<template>
  <div class="about">
    <el-table
        :data="theData.apiData"
        border
        style="width: 100%">
      <el-table-column
          prop="apiName"
          label="api名字"
      >
      </el-table-column>
      <el-table-column
          prop="api"
          label="api">
      </el-table-column>
      <el-table-column
          prop="apiActive"
          label="api状态">
        <template slot-scope="scope" >
          <div v-if="scope.row.apiActive==='正常'" style="background-color:#61f876;">
            {{ scope.row.apiActive }}
          </div>
          <div v-if="scope.row.apiActive==='异常'" style="background-color:#ff5454;">
            {{ scope.row.apiActive }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <hr>
    <h3>修改api信息</h3>
    <el-table
        :data="theData.reApiData"
        border
        style="width: 100%">
      <el-table-column
          prop="apiName"
          label="api名字"
      >
      </el-table-column>
      <el-table-column
          prop="api"
          label="api">
        <template slot-scope="scope">
          <el-input
              v-model="scope.row.api"
              placeholder="请输入api地址">
          </el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-button style="width: 100%;" type="primary" @click="reDate">修改</el-button>
  </div>
</template>
<script>
const axios = require('axios')
// axios.defaults.withCredentials=true
// axios.defaults.crossDomain=true
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// const theApi='https://tokenszapi.test.bibitest.top/'
export default {
  name: 'Home',
  components: {},
  mounted() {
    if (localStorage.api) {
      this.theData = JSON.parse(localStorage.api)
    }
    let that = this
    that.checkAllApi();
    for (let i = 1; i <= 100; i++) {
      clearInterval(i);
    }
    this.timeId= setInterval(function () {
      that.checkAllApi();
    }, 10000)
  },
  beforeDestroy() {
    window.clearInterval(this.timeId)
    },
  methods: {
    reStore: function () {
      localStorage.api = JSON.stringify(this.theData);
    },
    reDate: function () {
      this.theData.apiData[0].api = this.theData.reApiData[0].api
      this.theData.apiData[1].api = this.theData.reApiData[1].api
      this.theData.apiData[2].api = this.theData.reApiData[2].api
      this.theData.apiData[3].api = this.theData.reApiData[3].api
      this.theData.apiData[4].api = this.theData.reApiData[4].api
      this.reStore()
    },
    checkAllApi: async function () {
      await this.checkBianApi()
      await this.checkFtxApi()
      await this.checkOkApi()
      await this.checkGtApi()
      await this.checkMexcApi()
    },
    checkBianApi: async function () {
      let that = this
      // console.log(1)
      let response = await axios.get(this.theData.apiData[0].api + '/api/v3/ping'
      )
          .catch(function (error) {
            that.theData.apiData[0].apiActive = '异常'
            console.log(error);
          });
      if (response.status === 200) {
        this.theData.apiData[0].apiActive = '正常'
      } else {
        this.theData.apiData[0].apiActive = '异常'
      }
      console.log(0)
      console.log(response)
    },
    checkFtxApi: async function () {
      let that = this
      // console.log(2)
      let response = await axios.get('/ftx/markets')
          .catch(function (error) {
            that.theData.apiData[1].apiActive = '异常'
            console.log(error);
          });
      if (response.status === 200) {
        this.theData.apiData[1].apiActive = '正常'
      } else {
        this.theData.apiData[1].apiActive = '异常'
      }
      console.log(1)
      console.log(response)
    },
    checkOkApi: async function () {
      let that = this
      // console.log(2)
      let response = await axios.get('/ok/api/v5/market/tickers?instType=SWAP')
          .catch(function (error) {
            that.theData.apiData[2].apiActive = '异常'
            console.log(error);
          });
      if (response.status === 200) {
        this.theData.apiData[2].apiActive = '正常'
      } else {
        this.theData.apiData[2].apiActive = '异常'
      }
      console.log(2)
      console.log(response)
    },
    checkGtApi: async function () {
      let that = this
      // console.log(1)
      let response = await axios.get('/gt/spot/currencies'
      )
          .catch(function (error) {
            that.theData.apiData[3].apiActive = '异常'
            console.log(error);
          });
      if (response.status === 200) {
        this.theData.apiData[3].apiActive = '正常'
      } else {
        this.theData.apiData[3].apiActive = '异常'
      }
      console.log(3)
      console.log(response)
    },
    checkMexcApi: async function () {
      let that = this
      // console.log(1)
      let response = await axios.get('mexc/open/api/v2/market/symbols'
      )
          .catch(function (error) {
            that.theData.apiData[4].apiActive = '异常'
            console.log(error);
          });
      if (response.status === 200) {
        this.theData.apiData[4].apiActive = '正常'
      } else {
        this.theData.apiData[4].apiActive = '异常'
      }
      console.log(4)
      console.log(response)
    },
  },
  data() {
    return {
      timeId:'',
      theData: {
        apiData: [
          {
            apiName: '币安-api',
            api: 'https://api.binance.com',
            apiActive: '正常',
            key: 'Iqey7jKLeTYu3iRdXpKP4Yyst2sBZooFYb4W1sPRBUOek9pGVYjvCNpEKlznDsns',
            S: 'k2hZshhg1LB0r0iugRBExGTbn10w5MCfVOeWIDKHCob0fscJ28gGucWgrk9gRVnH'
          },
          {
            apiName: 'FTX-api',
            api: 'https://ftx.com/api',
            apiActive: '正常',
            key: 'ehUG0G0W7CscGENacJTgoAbDxfLcDSXTA72P73oy',
            S: 'Qs9ywf1jQeo3cQPo6MV65ywZD0vLrYmLwvTzML2PH'
          },
          {
            apiName: 'OK-api',
            api: 'https://www.okex.com/',
            apiActive: '正常',
            key: '5c162a96-09aa-4e32-984a-8bf7842c1597',
            S: '9AED5403089E3DCE3461D21A0E66EE28'
          },
          {
            apiName: 'GT-api',
            api: 'https://api.gateio.ws/api/v4',
            apiActive: '正常',
            key: '6336357bba41f5a7b5e43a4cab07cf6e',
            S: 'k2hZshhg1LB0r0iugRBExGTbn10w5MCfVOeWIDKHCob0fscJ28gGucWgrk9gRVnH'
          },
          {
            apiName: '抹茶-api',
            api: 'https://www.mexc.com',
            apiActive: '正常',
            key: 'mx0ZJck0Ih3jxLTDgx',
            S: 'e6cf94f234dc9f9d991bd8211c67d9f702a1e57f44c982c7458501a1140381fa'
          },
        ],
        reApiData: [
          {
            apiName: '币安-api',
            api: 'https://api.binance.com',

          },
          {
            apiName: 'FTX-api',
            api: 'https://ftx.com/api',

          },
          {
            apiName: 'OK-api',
            api: 'https://www.okex.com/',
          },
          {
            apiName: 'GT-api',
            api: 'https://api.gateio.ws/api/v4',

          },
          {
            apiName: '抹茶-api',
            api: 'https://www.mexc.com',

          },
        ]
      }
    }
  },

}
</script>
<template>
  <div class="about2">
    <el-table
        :data="newTokens"
        border
        style="width: 100%">
      <el-table-column
          prop="tokenName"
          label="币种"
      >
      </el-table-column>
      <el-table-column
          prop="link"
          label="交易所"
      >
        <template slot-scope="scope">
          {{reLink(scope.row.link)}}
        </template>
      </el-table-column>
      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-button style="width: 100%;" type="primary" @click="clearOne(scope.row.tokenName)">清除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button style="width: 100%;" type="primary" @click="clearAll">清除所有</el-button>
  </div>
</template>
<script>
export default {
  name: 'About2',
  components: {},
  mounted() {
    console.log(localStorage.newTokens)
    if (localStorage.newTokens) {
      this.newTokens = JSON.parse(localStorage.newTokens)
    }
    for (let i = 1; i <= 1000; i++) {
      clearInterval(i);
    }
    setInterval(function () {
      if (localStorage.newTokens) {
        this.newTokens = JSON.parse(localStorage.newTokens)
      }
    }, 500)
  },
  beforeDestroy() {
  },
  data() {
    return{
      newTokens:[]
    }
  },
  methods: {
    clearAll: function () {
      this.newTokens=[]
      if (localStorage.newTokens) {
        localStorage.newTokens = JSON.stringify( this.newTokens)
      }

    },
    clearOne:function (tokenName){

      for(let i=0;i<this.newTokens.length;i++){
        if(this.newTokens[i].tokenName===tokenName){
          this.newTokens.splice(i,1);
          localStorage.newTokens = JSON.stringify(this.newTokens)
        }
      }
    },
    reLink(theLink){
      let theLinkName={
        bianxianhuo: '币安现货',
        bianyongxu: '币安永续',
        biandangji: '币安当季',
        bianciji: '币安次季',
        tfxxianhuo: 'TFX现货',
        tfxyongxu: 'TFX永续',
        tfxdangji: 'TFX当季',
        tfxciji: 'TFX次季',
        okxianhuo: 'OK现货',
        okyongxu: 'OK永续',
        okdangzhou: 'OK当季',
        okcizhou: 'OK次季',
        okdangji: 'OK当季',
        okciji: 'OK次季',
        gtxianhuo: 'GT现货',
        gtyongxu: 'GT永续',
        mochaxianhuo: '抹茶现货',
        mochayongxu: '抹茶永续',
      }[theLink]
     if(theLinkName){
       return theLinkName
     }else{
       return theLink
     }
    }
  },

}
</script>